import './App.css';
import './styles/style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home.jsx';
import Adonay from './Adonay.jsx';
import Flowers from './Flowers.jsx'

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/adonay' element={<Adonay/>} />
      <Route path='/flowers' element={<Flowers/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
