import React from 'react';
import { useNavigate } from 'react-router-dom';
import Adonay from './Adonay';

const Home = () => {
    const navigate = useNavigate();
    return (
        <div className='home'>
            <h1>test</h1>    
            <button onClick={() => navigate("/adonay")}>lien</button>
            <button onClick={test}>lien</button>
        </div>
    );
}

const test = () => {
    alert("test");
}


export default Home;