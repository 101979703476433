const Flowers = () => {
    const plantList = [{
                        category: "classique",
                        id: "1ed",
                        isBestSale: false,
                        name: "monstera"
                    },
                        {
                        category: "classique",
                        id: "2ed",
                        isBestSale: true,
                        name: "ficus lyrata"
                    },
                    {
                        category: "classique",
                        id: "3ed",
                        isBestSale: false,
                        name: "pothos argenté"
                    }, 
                    {
                        category: "classique",
                        id: "4ed",
                        isBestSale: false,
                        name: "yucca"
                    }, 
                    {
                        category: "classique",
                        id: "5ed",
                        isBestSale: false,
                        name: "palmier"
                    }]
    return (<ul>
        {plantList.map((flower, index) => (<li key={index}> {flower.name} {flower.isBestSale && "👍"} <Id id={flower.id}/></li>))}
    </ul>)
}


const Id = ({id}) => (<p>{id}</p>)

export default Flowers;